import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light">
				<Header />
				<Hero type="promoover" />
				<div className="mx-auto -mt-12 mb-12 max-w-[1400px] text-center lg:mt-12 lg:mb-24">
					<h3 className="mb-12 mt-6 px-4 font-Veener text-[32px] uppercase text-red lg:mt-0 lg:px-0 lg:text-[52px]">
						Unsere GRATIS-TESTEN-AKTION ist seit dem 16.09.2024 beendet.
					</h3>
					<p className="mb-4 px-4 text-[22px] text-green">Vielen Dank an alle, die an unserer Aktion teilgenommen haben.</p>
					<p className="mb-4 px-4 text-[22px] text-green">
						Du hast noch Fragen zur Aktion? Kein Problem! Kontaktiere uns einfach per E-Mail unter{" "}
						<a className="underline" target="_blank" href="mailto:info@volvic-gratis-testen.de">
							info@volvic-gratis-testen.de
						</a>
					</p>
					<p className="mb-12 px-4 text-[22px] text-green lg:mb-24">Besuche uns gerne auf volvic.de, um nichts zu verpassen.</p>
					<a href="https://www.volvic.de/" target="_blank" className="btn mx-auto  font-Veener text-[42px] uppercase text-white drop-shadow-md hover:drop-shadow-xl">
						{" "}
						Zur Volvic Website
					</a>
				</div>
			</div>
		</>
	)
}
export default Confirm
